export const colAligin = {
  align: 'right',
  headerAlign: 'center',
  footerAlign: 'right',
}

export const waibiBegin = [
  {
    field: 'periodBeginInWb',
    title: '外币借方',
    ...colAligin,
    minWidth: 90,
    slots: {
      default: 'periodBeginInWb'
    }
  },
  {
    field: 'periodBeginOutWb',
    title: '外币贷方',
    ...colAligin,
    minWidth: 90,
    slots: {
      default: 'periodBeginOutWb'
    }
  }
]

export const shuliangBegin = [
  {
    field: 'beginCount',
    title: '数量',
    minWidth: 90,
    ...colAligin,
    slots: {
      default: 'beginCount'
    }
  }
]

export const periodInt = {
  field: 'periodInt',
  title: '外币借方',
  minWidth: 90,
  ...colAligin,
  slots: {
    default: 'periodInt'
  }
}

export const inCount = [
  {
    field: 'inCount',
    title: '数量借',
    minWidth: 90,
    ...colAligin,
    slots: {
      default: 'inCount'
    }
  }
]

export const periodOut = {
  field: 'periodOut',
  title: '外币借方',
  minWidth: 90,
  ...colAligin,
  slots: {
    default: 'periodOut'
  }
}

export const outCount = [
  {
    field: 'outCount',
    title: '数量贷',
    minWidth: 90,
    ...colAligin,
    slots: {
      default: 'outCount'
    }
  }
]

export const waibiPeriod = [
  {
    field: 'periodIntWb',
    title: '外币借方',
    minWidth: 90,
    ...colAligin,
    slots: {
      default: 'periodIntWb'
    }
  },
  {
    field: 'periodOutWb',
    title: '外币贷方',
    minWidth: 90,
    ...colAligin,
    slots: {
      default: 'periodOutWb'
    }
  }
]

export const waibiYear = [
  {
    field: 'yearIntWb',
    title: '外币借方',
    minWidth: 90,
    ...colAligin,
    slots: {
      default: 'yearIntWb'
    }
  },
  {
    field: 'yearOutWb',
    title: '外币贷方',
    minWidth: 90,
    ...colAligin,
    slots: {
      default: 'yearOutWb'
    }
  }
]

export const waibiperiodEnd = [
  {
    field: 'periodEndInWb',
    title: '外币借方',
    minWidth: 90,
    ...colAligin,
    slots: {
      default: 'periodEndInWb'
    }
  },
  {
    field: 'periodEndOutWb',
    title: '外币贷方',
    minWidth: 90,
    ...colAligin,
    slots: {
      default: 'periodEndOutWb'
    }
  }
]

export const shuliangperiodEnd = [
  {
    field: 'endCount',
    title: '数量',
    minWidth: 90,
    ...colAligin,
    slots: {
      default: 'endCount'
    }
  }
]

export const withInputKey = [
  'periodBeginIn',
  'periodBeginOut',
  'periodBeginInWb',
  'periodBeginOutWb',
  'beginCount',
  'yearInt',
  'yearOut',
  'yearIntWb',
  'yearOutWb',
]

export const noInputKeyWithTotal = [
  'periodInt',
  'periodOut',
  'periodEndIn',
  'periodEndOut',
]

export const noInputKeyNoTotal = [
  'inCount',
  'outCount',
  'endCount',
  'periodIntWb',
  'periodOutWb',
  'periodEndInWb',
  'periodEndOutWb',
]