<template>
  <div class="app-container">
    <div class="right_btn">
      <el-button size="small" type="primary" icon="Plus" @click="add"
        >新增</el-button
      >
    </div>
    <el-table
      :data="typeList"
      style="width: 100%"
      border
      v-loading="loading"
      @row-click="rowClick"
    >
      <el-table-column type="index" label="序号" width="55" align="center" />
      <el-table-column label="单据类型" width="150">
        <template #default="scope">
          <span v-if="scope.row.type == 2">合同</span>
          <span v-if="scope.row.type == 3">收款</span>
          <span v-if="scope.row.type == 4">退款</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateName" label="最后修改人" width="150">
      </el-table-column>
      <el-table-column label="启用状态" width="150">
        <template #default="scope">
          <el-tag :type="scope.row.status == 1 ? 'success' : 'info'">{{
            scope.row.status == 1 ? "启用" : "关闭"
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="最后修改日期" width="150">
        <template #default="scope">
          <span>{{ $parseTime(scope.row.updatedAt, "{y}-{m}-{d}") }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="receiveName" label="操作" width="150">
        <template #default="scope">
          <el-button size="small" link @click.stop="edit(scope.row)"
            >修改</el-button
          >
          <el-button
            size="small"
            type="danger"
            link
            @click.stop="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-table
      :data="list"
      style="width: 100%; margin-top: 10px"
      border
      v-loading="loading"
    >
      <el-table-column type="index" label="序号" width="55" align="center" />
      <el-table-column prop="req" label="审核顺序" width="150" align="center">
      </el-table-column>
      <el-table-column prop="userName" label="审核人名称" width="150">
      </el-table-column>
    </el-table>
  </div>
  <orderForm ref="orderFormRef" @success="getList" />
</template>
<script setup>
import { ref, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { approvalTypeList, approvalList, delApproval } from "@/api/newCrm";
import orderForm from "./components/orderForm.vue";
const list = ref([]);
const loading = ref(false);
const orderFormRef = ref();
const type = ref();
const typeList = ref([]);
onMounted(() => {
  getList();
});
const getList = async () => {
  await approvalTypeList({}).then((res) => {
    typeList.value = res.data.data.list ? res.data.data.list : [];
    if (!type.value && typeList.value?.length) {
      type.value = typeList.value[0].type;
    }
  });
  if (type.value) {
    approvalList({ type: type.value }).then((res) => {
      if (res.data.msg == "success") {
        list.value = res.data.data.list;
      }
    });
  }
};
const add = () => {
  orderFormRef.value.init();
};
const edit = (row) => {
  orderFormRef.value.init(row);
};
const del = (row) => {
  ElMessageBox.confirm("确定要删除此单据类型的审批流程吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    delApproval({ type: row.type }).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success("操作成功");
        type.value = "";
        list.value = [];
        getList();
      }
    });
  });
};
const rowClick = (row) => {
  approvalList({ type: row.type }).then((res) => {
    if (res.data.msg == "success") {
      list.value = res.data.data.list;
    }
  });
};
</script>
<script>
export default {
  name: "checkSet",
};
</script>

<style scoped lang="scss">
.right_btn {
  text-align: right;
  margin-bottom: 10px;
}
</style>
